'use strict';

// Libraries
import svgPanZoom from "svg-pan-zoom";
import Hammer from "hammerjs";

// Components
import Modal from "./Modal";
import Popin from "./Popin";

// Utils
import {addToCart, getSeatsPlan, initCart, deleteFromCart, generateErrorBanner, removeErrorBanner} from "../utils/cart-apis";
import {slugify, priceFormatString, rangePricesString} from "../utils/transformText";
import {getLocalStorage, removeLocalStorage, saveLocalStorage} from "../utils/local-storage";


const placesNumberLimit = 5000;

const Plan = {
    loaded: false,
    eventId: null,
    showId: null,
    component: null,
    title: null,
    iconPanZoom: null,
    svg: null,
    selectionInsert: null,
    panZoom: null,
    panZoomThumb: null,
    controlZoom: null,
    controlDezoom: null,
    selectedPlaces: [],
    priceTag: null,
    typePlanId: null,
    typePlanName: null,
    categoriesArray: [],
    seatsArray: [],
    sliderPeopleButtons: null,
    fossePrices: [],
    fosseCount: 0,
    modalPrices: null,
    accessCode: null,
    activatedAccessCode: false,
    fosseInputPosition: {},
    customClasses: getLocalStorage("customClasses") ? JSON.parse(getLocalStorage("customClasses")) : [],
    init: function () {
        Plan.component = document.querySelector('.c-plan');
        if (Plan.component) {

            Plan.getShowId();

            if (Plan.showId) {

                Plan.title = Plan.component.querySelector('.h1');
                Plan.svg = Plan.component.querySelector('.c-plan__svg');
                Plan.iconPanZoom = Plan.svg.querySelector('.c-plan__svg-icon');
                Plan.controlZoom = Plan.svg.querySelector('.c-plan__svg-tools-control-zoom');
                Plan.controlDezoom = Plan.svg.querySelector('.c-plan__svg-tools-control-dezoom');
                Plan.selectionInsert = Plan.component.querySelector('.c-plan__selection');
                Plan.modalPrices = Plan.component.querySelector('#modal-prices');

                if (Plan.svg) {
                    const svg = Plan.svg.querySelector('svg');
                    if (svg) {
                        // Init cart
                        initCart();

                        // Get plan places infos
                        getSeatsPlan(Plan.eventId, Plan.showId, Plan.updatePlanDatas, () => {
                            Plan.toggleLoader('hide', true);
                            const places = Plan.svg.querySelectorAll('.plan-places');
                            if (places && places.length > 0) {
                                // set all unavailable
                                places.forEach(place => {
                                    place.classList.add('unavailable');
                                });
                            }
                        });

                        // Observe selectedPlaces Array and call a function when push item inside
                        Plan.selectedPlaces.push = function () {
                            Array.prototype.push.apply(this, arguments);
                            Plan.observeSelectedPlacesChange();
                        };


                        // on click, manage add/delete place
                        svg.addEventListener('click', (event) => {
                            Plan.handlePlacesClick(event);
                        });
                        svg.addEventListener('touchend', (event) => {
                            Plan.handlePlacesClick(event);
                        });

                        // enable pan and zoom on plan
                        Plan.enablePanZoom(svg);

                        window.addEventListener('resize', () => {
                            if (Plan.panZoom) {
                                Plan.panZoom.resize();
                                Plan.panZoom.fit();
                                Plan.panZoom.center();
                            }
                        });


                        const buttonPromoCode = Plan.component.querySelector('[data-modal="modal-promocode"]');
                        if (buttonPromoCode) {
                            buttonPromoCode.addEventListener('click', () => {
                                Popin.showPopin(Plan.component.querySelector('#modal-promocode'));
                            });
                        }

                        const timerCheckAccessCode = setInterval(() => {
                            const accessCodeJSON = getLocalStorage('access_code');
                            const accessCode = JSON.parse(accessCodeJSON)
                            if (accessCode && accessCode.code !== "" && accessCode.code !== null && accessCode.showId === Plan.showId) {
                                if (Plan.activatedAccessCode === false) {
                                    Plan.updateWithAccessCode(accessCode.code);
                                    Plan.activatedAccessCode = true;
                                }
                            } else {
                                if (Plan.activatedAccessCode === true) {
                                    Plan.updateWithAccessCode();
                                    Plan.activatedAccessCode = false;
                                }
                            }
                        }, 1000);
                    }

                    // Manage tooltip insert
                    if (Plan.selectionInsert) {
                        Plan.handleInsert();
                    }
                }
            } else {
                generateErrorBanner('missingEventID', 'Aucun événement trouvé');
                Plan.toggleLoader('hide', true);
            }
        }
    },
    toggleLoader: function (type, err) {
        const loader = Plan.component.querySelector('.c-plan__loader');
        const insertSelection = Plan.component.querySelector('.c-plan__selection');

        // Show loader / Hide plan
        if (type === 'show') {
            // console.log('show loader');
            insertSelection.classList.add('hidden--');
            loader.classList.remove('hidden--');
        }
        // Hide loader / Show plan
        if (type === 'hide') {
            // console.log('hide loader');
            const timer = setInterval(() => {
                if (Plan.loaded === true) {
                    insertSelection.classList.remove('hidden--');
                    loader.classList.add('hidden--');
                    clearInterval(timer);
                } else if (err) {
                    insertSelection.classList.remove('hidden--');
                    loader.classList.add('hidden--');
                    clearInterval(timer);
                }
            }, 100);

        }
    },
    getShowId: function () {
        if (window.location.href.indexOf("/static/choix-sur-plan") > -1) {
            const url = new URL(window.location.href);
            Plan.showId = url.searchParams.get('showId');
            Plan.eventId = url.searchParams.get('eventId');
        } else {
            Plan.showId = Plan.component.querySelector('#id_show').value;
            Plan.eventId = Plan.component.querySelector('#id_post_evenement').value;
        }
    },
    updatePlanDatas: function (data) {
        if (data) {
            // console.log('updatePlaces', data);

            if (data.prices) {
                Plan.updateCategoriesModal(data.prices);
            }
            if (data.sections && data.show_infos) {
                Plan.updatePlaces(data.sections, data.show_infos);
            }
            if (data.show_infos) {
                // set type of plan default|fosse
                Plan.setTypePlan(Plan.svg.querySelector('svg'), data.show_infos);
            }
        }
    },
    updatePlaces: function (sections, showInfos) {
        if (sections) {
            const places = Plan.svg.querySelectorAll('.plan-places');

            if (places && places.length > 0) {

                // set all unavailable
                places.forEach(place => {
                    if (!place.classList.contains('selected')) {
                        place.classList.add('unavailable');
                    }

                    // manage hover on place
                    if (window.matchMedia('(min-width:1025px)').matches) {
                        place.addEventListener('mouseenter', (event) => {
                            Plan.handlePlacesHover(event);
                        });
                        place.addEventListener('mouseleave', (event) => {
                            Plan.hidePlacePricesPopup();
                        });
                    }
                });

                let totalAvailable = 0;

                sections.forEach(section => {
                    if (section.totalAvailableSeats) {
                        totalAvailable = totalAvailable + parseInt(section.totalAvailableSeats);
                    }
                    if (section.seats.length > 0) {
                        section.seats.forEach(seat => {
                            const place = Plan.svg.querySelector('#' + seat.id);
                            place.classList.remove('unavailable');
                            const catId = seat.priceListAvailable[0].id;
                            const classList = Plan.newClassStyle(slugify(Plan.categoriesArray.find(cat => cat.id === catId).priceCategory));
                            Plan.newClassStyle(classList, place);

                            Plan.seatsArray.push(seat);
                        });
                    } else {
                        if (showInfos.seatingMap.name === 'fosse') {
                            if (section.name === 'orchestre') {
                                if (section.totalAvailableSeats > 0) {
                                    Plan.fossePrices = section.prices;

                                    const container = Plan.svg.querySelector('.fosse__input'), input = container.querySelector('input[type="number"]');
                                    input.max = `${placesNumberLimit < section.totalAvailableSeats ? placesNumberLimit : section.totalAvailableSeats}`;
                                    Plan.updateRangeFosseInput();

                                    const cats = [];
                                    if (Plan.fossePrices.length > 0) {
                                        Plan.fossePrices.forEach(price => {
                                            cats.push(slugify(price.priceCategory));
                                        });
                                        Plan.createFosseCategoriesBullets(cats);
                                    }


                                    // disable keyboard on input number
                                    input.addEventListener("keypress", (event) => {
                                        event.preventDefault();
                                    });

                                    const btnUp = container.querySelector('.quantity-up'), btnDown = container.querySelector('.quantity-down'), min = input.min, max = input.max;

                                    btnUp.addEventListener('click', (event) => {
                                        const svg = Plan.svg.querySelector('svg');
                                        if (!svg.classList.contains('full')) {
                                            removeErrorBanner('fosse-full');
                                            if (Plan.selectedPlaces.length < placesNumberLimit) {
                                                Plan.beforeAddPlace("fosse-" + input.value, true, event);
                                            }
                                        } else {
                                            Plan.toggleLoader('hide', true);
                                            generateErrorBanner('limit', "Le nombre limite de places est atteint");
                                        }
                                    });

                                    btnDown.addEventListener('click', () => {
                                        removeErrorBanner('fosse-full');
                                        Plan.toggleLoader('show');
                                        deleteFromCart(Plan.showId, Plan.selectedPlaces.find(item => item.fosse === true), Plan.deletePlace, () => Plan.toggleLoader('hide', true));
                                    });
                                } else {
                                    Plan.svg.querySelector('svg').classList.add('full');
                                }
                            }
                        }
                    }
                });


                if (totalAvailable === 0) {
                    // console.log('err getSeatsPlan : ' + "Il n'y a plus aucune place disponible");
                    generateErrorBanner('getSeatsPlan-err-available', "Il n'y a plus aucune place disponible");
                    Plan.toggleLoader('hide', true);
                }
            }

            Plan.loaded = true;
            Plan.toggleLoader('hide');
        }
    },
    newClassStyle: function (classList, item = null) {
        if (classList !== 'carre-or' && classList !== "cat-gold" && classList !== 'cat-1' && classList !== 'cat-2' && classList !== 'cat-3' && classList !== 'cat-4' && classList !== 'cat-5') {
            classList = classList.split(' ')[0];

            const hours = 4; // to clear the localStorage after 4 hours
            const now = new Date().getTime();
            const setupTime = getLocalStorage('setupTimeCustomClasses');
            if (setupTime == null) {
                saveLocalStorage('setupTimeCustomClasses', now)
            } else {
                if(now-setupTime > hours*60*60*1000) {
                    removeLocalStorage('customClasses');
                    removeLocalStorage('setupTimeCustomClasses');
                    Plan.customClasses=[];
                    saveLocalStorage('setupTimeCustomClasses', now);
                }
            }

            const arrayLength = Plan.customClasses.length;
            if (Plan.customClasses.filter(e => e.class === classList).length === 0) {
                /* Plan.customClasses doesn't contain the class we're looking for */
                Plan.customClasses.push({'class': classList, 'customClass': 'cat-' + (arrayLength + 6)});

                saveLocalStorage('customClasses', JSON.stringify(Plan.customClasses));
                if (item) {
                    item.classList.add(classList, `cat-${arrayLength + 6}`);
                } else {
                    return classList + ' cat-' + (arrayLength + 6);
                }
            } else {
                if (item) {
                    item.classList.add(classList, Plan.customClasses.filter(e => e.class === classList)[0].customClass);
                } else {
                    return classList + ' ' + Plan.customClasses.filter(e => e.class === classList)[0].customClass;
                }
            }
        } else {
            if (item) {
                item.classList.add(classList);
            } else {
                return classList;
            }
        }
    },
    updateRangeFosseInput: function (resize = false) {
        const rangePrices = Plan.component.querySelector('.fosse__input:not(.sample--) .fosse__input-prices');
        const labelQuantity = Plan.component.querySelector('.fosse__input:not(.sample--) label[for="fosse-quantity"]');
        if (labelQuantity) {
            labelQuantity.innerHTML = Plan.component.dataset.quantity;
        }
        const sample = Plan.component.querySelector('.fosse__input.sample-- .fosse__input-prices');

        let prices = [];
        Plan.fossePrices.forEach(price => {
            if (price.hasCodesPromo === true) {
                const accessCodeJSON = getLocalStorage('access_code');
                const accessCode = JSON.parse(accessCodeJSON)
                if (accessCode && accessCode.code !== "" && accessCode.code !== null && accessCode.showId === Plan.showId) {
                    if(accessCode.prices.some(codePrice => codePrice.priceId === price.id))
                        prices.push(price.valueCents);
                }
            } else {
                prices.push(price.valueCents);
            }
        });

        if (rangePrices) {
            if (prices.length > 1) {
                rangePrices.innerHTML = rangePricesString(prices);
                sample.innerHTML = rangePricesString(prices);
            } else {
                rangePrices.innerHTML = priceFormatString(prices[0]) + '€';
                sample.innerHTML = priceFormatString(prices[0]) + '€';
            }
        }

        if (resize) {
            Plan.handleResizeFosseInput();
        }

    },
    setTypePlan: function (svg, showInfos) {
        svg.classList.add(showInfos.seatingMap.name);
        Plan.typePlanId = showInfos.seatingMap.id;
        Plan.typePlanName = showInfos.seatingMap.name;
    },
    updateCategoriesModal: function (prices) {
        if (prices) {
            Plan.categoriesArray = prices;
        } else {
            prices = Plan.categoriesArray;
        }

        const ctn = document.querySelector('#modal-planCategories .checkboxes');
        ctn.innerHTML = '';
        const pricesIdList = [];
        const pricesCategoryIdList = [];


        prices.forEach(price => {
            if (price.hasCodesPromo === true) { //si dans la liste il y a un code promo => affichage encart code promo
                const buttonPromoCode = Plan.component.querySelector('[data-modal="modal-promocode"]');
                if (buttonPromoCode) {
                    if (buttonPromoCode.classList.contains('hidden--')) {
                        buttonPromoCode.classList.remove('hidden--')
                    }
                }
            }
            //recupere la liste des categories de prix
            if (!pricesCategoryIdList.includes(price.priceCategoryId)) {
                pricesCategoryIdList.push(price.priceCategoryId);
                pricesIdList.push(price);
            }
        });

        //Bloc Filtrer par catégorie
        pricesIdList.forEach(price => {

            const pricesRange = [];
            const itemsWithSamePriceCategory = prices.filter(item => item.priceCategoryId === price.priceCategoryId);
            itemsWithSamePriceCategory.forEach(item => {
                if (item.hasCodesPromo === true) {
                    const accessCodeJSON = getLocalStorage('access_code');
                    const accessCode = JSON.parse(accessCodeJSON)
                    if (accessCode && accessCode.code !== "" && accessCode.code !== null && accessCode.showId === Plan.showId) {
                        if(accessCode.prices.some(price => price.priceId === item.id))
                            pricesRange.push(item.valueCents);
                    }
                } else {
                    pricesRange.push(item.valueCents);
                }
            });


            // create markup
            const html = `
                <div class="checkboxCtn">
                    <label for="${price.id}">
                        <span>
                            <span class="${Plan.newClassStyle(slugify(price.priceCategory))}"></span>
                            ${price.name}
                        </span>
                        <strong>${pricesRange.length === 1 ? priceFormatString(pricesRange[0]) + '€' : rangePricesString(pricesRange)}</strong>
                    </label>
                    <input type="checkbox" value="${slugify(price.priceCategory)}" id="${price.id}" name="categories" data-cat="${price.priceCategoryId}"/>
                    <span></span>
                </div>
            `;

            ctn.innerHTML += html;
        });

        // Filter by categories
        const categoriesModal = document.querySelector('#modal-planCategories');
        if (categoriesModal) {
            Plan.manageCategoriesModal(categoriesModal);
        }
    },
    manageCategoriesModal: function (categoriesModal) {
        const formCategories = categoriesModal.querySelector('.c-form');
        if (formCategories) {
            let checkboxes = formCategories.querySelectorAll('input[name="categories"]');
            if (checkboxes) {
                const all = formCategories.querySelector('#categories-all');
                if (all) {
                    all.addEventListener('change', () => {
                        if (all.checked === true) {
                            for (let checkbox of checkboxes) {
                                if (checkbox.id !== "categories-all") {
                                    checkbox.checked = true;
                                    checkbox.disabled = true;
                                }
                            }
                        } else {
                            for (let checkbox of checkboxes) {
                                if (checkbox.id !== "categories-all") {
                                    checkbox.checked = false;
                                    checkbox.disabled = false;
                                }
                            }
                        }
                    });
                }
                formCategories.addEventListener('change', () => {
                    let checkedItems = [];
                    for (let checkbox of checkboxes) {
                        if (checkbox.id !== "categories-all") {
                            if (checkbox.checked) {
                                checkedItems.push(checkbox);
                            }
                        }
                    }
                    if (checkedItems.length === checkboxes.length - 1) {
                        if (all) {
                            all.checked = true;
                        }
                        for (let checkbox of checkboxes) {
                            if (checkbox.id !== "categories-all") {
                                checkbox.checked = true;
                                checkbox.disabled = true;
                            }
                        }
                    }
                });

                const reset = formCategories.querySelector('.reset button');
                if (reset) {
                    reset.addEventListener('click', () => {
                        formCategories.reset();
                        for (let checkbox of checkboxes) {
                            checkbox.checked = false;
                            checkbox.disabled = false;
                        }
                    });
                }
                const submit = formCategories.querySelector('.js-submit');
                if (submit) {
                    submit.addEventListener('click', () => {
                        const activeCategories = [];
                        for (let checkbox of checkboxes) {
                            if (checkbox.checked) {
                                // console.log(checkbox.id, checkbox.value);
                                activeCategories.push(checkbox.value);
                            }
                        }

                        if (Plan.typePlanName === 'fosse') {
                            Plan.createFosseCategoriesBullets(activeCategories);
                        }

                        const places = Plan.svg.querySelectorAll('.plan-places');
                        places.forEach(place => {
                            if (!place.classList.contains('unavailable')) {
                                if (activeCategories.length > 0) {
                                    place.dataset.active = false;
                                    activeCategories.forEach(cat => {
                                        if (place.classList.contains(cat)) {
                                            place.dataset.active = true;
                                        }
                                    });
                                } else {
                                    place.dataset.active = true;
                                }
                            }
                        });

                        Modal.close(categoriesModal, false);

                        Plan.panZoom.resize();
                        Plan.panZoom.updateBBox(); // Update viewport bounding box();
                        Plan.panZoom.fit();
                        Plan.panZoom.center();
                    });
                }
            }
        }
    },
    createFosseCategoriesBullets: function (activeCategories) {
        if (activeCategories.length > 0) {
            let bullets = [];
            activeCategories.forEach(cat => {
                const categories = Plan.fossePrices.filter(price => slugify(price.priceCategory) === cat);

                if (categories.length > 0) {
                    categories.forEach(bullet => {
                        if (!bullets.includes(slugify(bullet.priceCategory))) {
                            bullets.push(slugify(bullet.priceCategory));
                        }
                    });
                }
            });

            const sample = Plan.svg.querySelector('.c-plan__svg-samplebullets');
            sample.innerHTML = "";
            const elCtn = Plan.svg.querySelector('.fosse__categories-ctn');
            elCtn.innerHTML = "";

            if (sample && elCtn) {
                if (bullets.length > 0) {
                    bullets.forEach(bullet => {
                        sample.innerHTML += '<span class="' + Plan.newClassStyle(bullet) + '"></span>';
                        elCtn.innerHTML += '<span class="' + Plan.newClassStyle(bullet) + '"></span>';
                    });
                }
                Plan.updateFosseCategoriesBulletsZoom();
            }
        }

    },
    updateFosseCategoriesBulletsZoom: function () {
        const sample = Plan.svg.querySelector('.c-plan__svg-samplebullets');
        const svgBox = Plan.panZoom.getSizes(),
            realZoom = svgBox.realZoom;

        if (sample && realZoom) {
            const elCtn = Plan.svg.querySelector('.fosse__categories-ctn');
            const elInner = Plan.svg.querySelector('.fosse__categories-ctnInner');

            const span = elCtn.querySelectorAll('span');
            if (span && span.length > 0) {
                span.forEach((el) => {
                    el.style.width = sample.querySelectorAll('span')[0].getBoundingClientRect().width / realZoom + 'px';
                    el.style.height = sample.querySelectorAll('span')[0].getBoundingClientRect().width / realZoom + 'px';
                    el.style.marginLeft = 5 / realZoom + 'px';
                    el.style.marginRight = 5 / realZoom + 'px';
                })
            }

            elInner.setAttribute('width', (sample.getBoundingClientRect().width + 2) / realZoom);
            elInner.setAttribute('height', sample.getBoundingClientRect().height / realZoom);

            elCtn.setAttribute('width', (sample.getBoundingClientRect().width + 2) / realZoom);
            elCtn.setAttribute('height', sample.getBoundingClientRect().height / realZoom);

            const x = svgBox.viewBox.width / 2 - sample.getBoundingClientRect().width / 2 / realZoom;
            const y = svgBox.viewBox.height / 2 + 60 - sample.getBoundingClientRect().height / realZoom;
            elCtn.setAttribute('x', x);
            elCtn.setAttribute('y', y);
        }
    },
    setSelectedPlacesOnLoad: function (entries) {
        if (entries.length > 0) {
            let counter = 0;
            let entriesInShow = entries.filter(entry => entry.showId === parseInt(Plan.showId)).length;
            entries.forEach(entry => {
                if (entry.showId === parseInt(Plan.showId)) {
                    counter++;
                    // console.log('setSelectedPlacesOnLoad entry :', entry);
                    if (!Plan.selectedPlaces.find(el => el.seeticketsId === entry.seatId)) {
                        const entryPositionSplit = entry.positionOld.split(' ');
                        const placeLocation = slugify(entryPositionSplit[0]);
                        let placeRow = '';
                        if (entryPositionSplit[3]) {
                            placeRow = slugify(entryPositionSplit[3]);
                        }
                        let placeNumber = '';
                        if (entryPositionSplit[5]) {
                            placeNumber = slugify(entryPositionSplit[5]);
                        }

                        let strapontin = false;
                        if (entry.position.indexOf('strapontin') > -1 || entry.position.indexOf('Jump seat') > -1) {
                            strapontin = true;
                        }

                        // if fosse
                        if (placeLocation == 'orchestre' && placeRow === 'debout') {
                            Plan.typePlanName = 'fosse';
                            Plan.fosseCount++;

                            const fosseInputCtnInner = Plan.svg.querySelector('.fosse__input-ctnInner');
                            if (fosseInputCtnInner) {
                                const fosseInput = fosseInputCtnInner.querySelector('.fosse__input');
                                if (fosseInput) {
                                    Plan.handleZoomFosseInput(fosseInput);
                                    fosseInputCtnInner.classList.add('visible');
                                }
                            }
                        }


                        const item = {
                            location: placeLocation,
                            row: placeRow,
                            number: placeNumber,
                            id: (placeLocation == 'orchestre' && placeRow === 'debout') ? 'fosse-' + Plan.fosseCount : placeLocation + '-' + placeRow + '-' + placeNumber,
                            strapontin: strapontin,
                            price: entry.priceValueCents,
                            priceName: entry.priceName,
                            priceId: entry.priceId,
                            cat: {slug: slugify(entry.priceCategoryName), name: entry.priceName},
                            // cat: {slug: slugify(entry.priceCategoryName), name: entry.priceName},
                            fosse: placeLocation == 'orchestre' && placeRow === 'debout',
                            seeticketsId: entry.seatId,
                            entryId: entry.id,
                            locationLang: entry.sectionName
                        };

                        // console.log('setSelectedPlacesOnLoad item :', item);

                        //console.log(entriesInShow, counter);
                        if (entriesInShow === counter) {
                            Plan.addPlace(item, true, true);
                        } else {
                            Plan.addPlace(item, true);
                        }


                    }
                }
            });
        } else {
            Plan.toggleLoader('hide');
        }
        Plan.observeSelectedPlacesChange();
    },
    observeSelectedPlacesChange: function () {
        Plan.updateSelectedPlacesLength();
        Plan.updateCartAmount();
    },
    updateSelectedPlacesLength: function () {
        const length = Plan.component.querySelector('.c-plan__selection-cart-tickets-length');
        const selectionPanel = Plan.component.querySelector('.c-plan__selection');


        if (length && selectionPanel) {

            if (Plan.typePlanName === 'fosse') {
                Plan.fosseCount = Plan.selectedPlaces.filter(item => item.fosse === true).length;


                const fosseInputCtnInner = Plan.svg.querySelector('.fosse__input-ctnInner');
                if (fosseInputCtnInner) {
                    const fosseInput = fosseInputCtnInner.querySelector('.fosse__input input');
                    if (fosseInput) {
                        fosseInput.value = Plan.fosseCount;
                        if (parseInt(fosseInput.value) === 0) {
                            fosseInputCtnInner.classList.remove('visible');
                        }
                    }
                }
            }

            if (Plan.selectedPlaces.length > 0) {
                length.innerHTML = Plan.selectedPlaces.length;
                selectionPanel.classList.add('active');
            } else {
                length.innerHTML = 0;
                selectionPanel.classList.remove('active');
            }
        }
    },
    updateCartAmount: function () {
        const amount = Plan.component.querySelector('.c-button__price');
        let total = 0;
        if (amount) {
            if (Plan.selectedPlaces.length > 0) {
                Plan.selectedPlaces.forEach(place => {
                    total = total + place.price;
                });
                amount.textContent = priceFormatString(total) + '€';
            } else {
                amount.textContent = '0€';
            }
        }
    },
    enablePanZoom: function (svg) {
        Plan.svg.addEventListener("wheel", function (e) {
            e.preventDefault();
        }, {
            passive: false
        });

        const beforePan = function (oldPan, newPan) {
            let stopHorizontal = false,
                stopVertical = false,
                sizes = this.getSizes();

            let gutterHeight, gutterWidth;
            if (window.matchMedia('(min-width:768px)').matches) {
                gutterHeight = sizes.viewBox.height * .4;
                gutterWidth = sizes.viewBox.width * 1.4;
            } else {
                gutterHeight = 100;// Computed variables,
                gutterWidth = 100;
            }

            let leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
                rightLimit = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
                topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
                bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom),
                customPan = {};

            customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
            customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));
            return customPan;
        };

        let refreshRateValue;
        let maxZoomValue;
        if (window.matchMedia('(max-width:1024px)').matches) {
            refreshRateValue = 10;
            maxZoomValue = 7;
        } else {
            refreshRateValue = 'auto';
            maxZoomValue = 4;
        }

        let eventsHandler;
        eventsHandler = {
            haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'], init: function (options) {
                let instance = options.instance, initialScale = 1, pannedX = 0, pannedY = 0
                // Init Hammer
                // Listen only for pointer and touch events
                this.hammer = Hammer(options.svgElement, {
                    inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
                })
                // Enable pinch
                this.hammer.get('pinch').set({enable: true})
                // Handle double tap
                this.hammer.on('doubletap', function (ev) {
                    instance.zoomIn()
                })
                // Handle pan
                this.hammer.on('panstart panmove', function (ev) {
                    // On pan start reset panned variables
                    if (ev.type === 'panstart') {
                        pannedX = 0
                        pannedY = 0
                    }
                    // Pan only the difference
                    instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY})
                    pannedX = ev.deltaX
                    pannedY = ev.deltaY
                })
                // Handle pinch
                this.hammer.on('pinchstart pinchmove', function (ev) {
                    // On pinch start remember initial zoom
                    if (ev.type === 'pinchstart') {
                        initialScale = instance.getZoom()
                        instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
                    }
                    const el = ev.target;
                    const rect = el.getBoundingClientRect();

                    const pos = {
                        x: (ev.center.x - rect.left), y: (ev.center.y - rect.top)
                    };

                    instance.zoomAtPoint(initialScale * ev.scale, {x: pos.x, y: pos.y});
                })
                // Prevent moving the page on some devices when panning over SVG
                options.svgElement.addEventListener('touchmove', function (e) {
                    e.preventDefault();
                });
            }
            // Destroy custom events handler
            , destroy: function () {
                this.hammer.destroy()
            }
        };

        Plan.panZoom = svgPanZoom(svg, {
            // viewportSelector: Plan.component,
            panEnabled: true,
            controlIconsEnabled: false,
            zoomEnabled: true,
            dblClickZoomEnabled: true,
            mouseWheelZoomEnabled: true,
            preventMouseEventsDefault: false,
            zoomScaleSensitivity: .22,
            minZoom: 1,
            maxZoom: maxZoomValue,
            fit: true,
            contain: true,
            center: true,
            refreshRate: refreshRateValue,
            customEventsHandler: eventsHandler,
            beforePan: beforePan,
        });

        Plan.panZoomThumb = svgPanZoom('#thumbView', {
            zoomEnabled: false,
            panEnabled: false,
            controlIconsEnabled: false,
            dblClickZoomEnabled: false,
            mouseWheelZoomEnabled: false,
            preventMouseEventsDefault: false,
            customEventsHandler: eventsHandler,
        });

        if (window.matchMedia('(max-width:767px)').matches) {
            Plan.panZoom.zoom(.9);
        }

        Plan.handleSvgThumbnail(Plan.panZoom, Plan.panZoomThumb);

        Plan.handleZoomButtons();
    },
    handleZoomButtons: function () {
        if (Plan.controlZoom) {
            Plan.controlZoom.addEventListener('click', () => {
                if (!Plan.controlZoom.classList.contains('disabled--')) {
                    if (Plan.panZoom.getZoom() > .9) {
                        Plan.panZoom.zoomIn();
                    }
                }
            });
        }
        if (Plan.controlDezoom) {
            Plan.controlDezoom.addEventListener('click', () => {
                if (!Plan.controlDezoom.classList.contains('disabled--')) {
                    if (window.matchMedia('(max-width:1024px)').matches) {
                        if (Plan.panZoom.getZoom() < 7.1) {
                            Plan.panZoom.zoomOut();
                        }
                    } else {
                        if (Plan.panZoom.getZoom() < 4.1) {
                            Plan.panZoom.zoomOut();
                        }
                    }
                }
            });
        }
    },
    handleSvgThumbnail: function (main, thumb) {
        let resizeTimer;
        const interval = 300; //msec
        window.addEventListener('resize', function (event) {
            if (resizeTimer !== false) {
                clearTimeout(resizeTimer);
            }
            resizeTimer = setTimeout(function () {
                main.resize();
                thumb.resize();
            }, interval);
        });

        main.setOnZoom(function (level) {
            _updateThumbScope(main, thumb);
        });

        main.setOnPan(function (point) {
            _updateThumbScope(main, thumb);
            if (window.matchMedia('(max-width:767px)').matches) {
                Plan.title.classList.add('hidden--');
                Plan.iconPanZoom.classList.add('hidden--');
            }
        });

        const _updateThumbScope = function (main, thumb) {
            const zoom = main.getZoom();
            const scope = document.getElementById('scope');
            const mainPanX = main.getPan().x, mainPanY = main.getPan().y, mainWidth = main.getSizes().width, mainHeight = main.getSizes().height, mainZoom = main.getSizes().realZoom,
                thumbPanX = thumb.getPan().x, thumbPanY = thumb.getPan().y, thumbZoom = thumb.getSizes().realZoom;

            const thumByMainZoomRatio = thumbZoom / mainZoom;

            const scopeX = thumbPanX - mainPanX * thumByMainZoomRatio;
            const scopeY = thumbPanY - mainPanY * thumByMainZoomRatio;
            const scopeWidth = mainWidth * thumByMainZoomRatio;
            const scopeHeight = mainHeight * thumByMainZoomRatio;


            scope.setAttribute("x", scopeX + 16 + 1);
            scope.setAttribute("y", scopeY + 1);
            scope.setAttribute("width", scopeWidth - 2);
            scope.setAttribute("height", scopeHeight - 2);


            if (Plan.title) {
                if (zoom > 1.02) {
                    Plan.title.classList.add('hidden--');
                    Plan.iconPanZoom.classList.add('hidden--');
                } else {
                    if (Plan.title.classList.contains('hidden--')) {
                        Plan.title.classList.remove('hidden--');
                    }
                }
            }

            if (Plan.controlDezoom && Plan.controlZoom) {
                if (zoom < 3.9) {
                    if (Plan.controlZoom.classList.contains('disabled--')) {
                        Plan.controlZoom.classList.remove('disabled--')
                    }
                } else {
                    Plan.controlZoom.classList.add('disabled--')
                }
                if (zoom > 1.1) {
                    if (Plan.controlDezoom.classList.contains('disabled--')) {
                        Plan.controlDezoom.classList.remove('disabled--')
                    }
                } else {
                    Plan.controlDezoom.classList.add('disabled--')
                    Plan.panZoom.resize();
                    Plan.panZoom.fit();
                    Plan.panZoom.center();
                }
            }


            Plan.handleZoomPriceTag();

            if (Plan.typePlanName === 'fosse') {
                const fosseInput = Plan.svg.querySelector('.fosse__input');
                if (fosseInput) {
                    Plan.handleZoomFosseInput(fosseInput);
                }

                Plan.updateFosseCategoriesBulletsZoom();
            }
        };

        _updateThumbScope(main, thumb);

        const _updateMainViewPan = function (clientX, clientY, scopeContainer, main, thumb) {
            const dim = scopeContainer.getBoundingClientRect(), mainWidth = main.getSizes().width, mainHeight = main.getSizes().height, mainZoom = main.getSizes().realZoom,
                thumbWidth = thumb.getSizes().width, thumbHeight = thumb.getSizes().height, thumbZoom = thumb.getSizes().realZoom;

            const thumbPanX = clientX - dim.left - thumbWidth / 2;
            const thumbPanY = clientY - dim.top - thumbHeight / 2;
            const mainPanX = -thumbPanX * mainZoom / thumbZoom;
            const mainPanY = -thumbPanY * mainZoom / thumbZoom;
            main.pan({x: mainPanX, y: mainPanY});

            const fosseInput = Plan.svg.querySelector('.fosse__input');
            Plan.handleZoomFosseInput(fosseInput);
        };

        const updateMainViewPan = function (evt) {
            if (evt.which === 0 && evt.button === 0) {
                return false;
            }
            const scopeContainer = document.getElementById('scopeContainer');
            _updateMainViewPan(evt.clientX, evt.clientY, scopeContainer, main, thumb);
        }


        const scopeContainer = document.getElementById('scopeContainer');

        scopeContainer.addEventListener('click', function (evt) {
            updateMainViewPan(evt);
        });

        function handleMouse(evt) {
            updateMainViewPan(evt);
        }

        scopeContainer.addEventListener('mousedown', function (evt) {
            scopeContainer.addEventListener('mousemove', handleMouse, false);
            scopeContainer.addEventListener('mouseup', function (evt) {
                scopeContainer.removeEventListener('mousemove', handleMouse, false);
            });
        });

    },
    zoomSelectedPlace: function (item) {
        const svg = Plan.svg.querySelector('svg'), place = svg.getElementById(item.id), svgBox = Plan.panZoom.getSizes(), width = svgBox.width, height = svgBox.height;

        // console.log('zoomSelectedPlace item', item);
        // console.log('zoomSelectedPlace place', place);

        Plan.panZoom.zoom(3);


        let panX;
        let panY;
        let pathBBox = '';
        if (!item.fosse) {
            pathBBox = place.getBBox();
            // show place prices popup
            Plan.showPlacePricesPopup(place, item, svg, pathBBox);

            panX = width / 2 - pathBBox.x * Plan.panZoom.getSizes().realZoom - pathBBox.width / 2 * Plan.panZoom.getSizes().realZoom, panY = height / 2 - pathBBox.y * Plan.panZoom.getSizes().realZoom;
            if (window.matchMedia('(max-width:767px)').matches) {
                panY = height / 2 - pathBBox.y * Plan.panZoom.getSizes().realZoom - 40 * Plan.panZoom.getSizes().realZoom;
            }
        } else {
            const fosseInputCtnInner = Plan.svg.querySelector('.fosse__input-ctnInner.visible');
            if (fosseInputCtnInner) {
                pathBBox = fosseInputCtnInner.getBBox();

                panX = width / 2 - pathBBox.x * Plan.panZoom.getSizes().realZoom - pathBBox.width / 2 * Plan.panZoom.getSizes().realZoom, panY = height / 2 - pathBBox.y * Plan.panZoom.getSizes().realZoom - pathBBox.height * Plan.panZoom.getSizes().realZoom;
                if (window.matchMedia('(max-width:767px)').matches) {
                    panY = height / 2 - pathBBox.y * Plan.panZoom.getSizes().realZoom - pathBBox.height * Plan.panZoom.getSizes().realZoom - 15 * Plan.panZoom.getSizes().realZoom;
                }

            }
        }
        if (panX && panY) {
            Plan.panZoom.pan({x: panX, y: panY});
        }
    },
    hidePlacePricesPopup: function () {
        // remove old group and price tag
        const old = Plan.svg.querySelector('.selected-path');
        if (old) {
            const oldPathSection = old.parentElement;
            const oldPath = old.querySelector('path');
            oldPathSection.append(oldPath);
            old.remove();
        }
    },
    showPlacePricesPopup: function (place, item, svg, pathBBox) {
        const svgBox = Plan.panZoom.getSizes(), realZoom = svgBox.realZoom;
        const sample = Plan.svg.querySelector('.c-plan__svg-sampleprice');
        if (Array.isArray(item.price)) {
            sample.innerHTML = rangePricesString(item.price);
        } else {
            sample.innerHTML = priceFormatString(item.price) + "€";
        }

        // Group around path place
        const section = place.closest('g');

        Plan.priceTag = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        Plan.priceTag.classList.add('selected-path');

        // Create price tag
        const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        foreignObject.classList.add('c-plan__svg-price-ctn', item.cat.slug);
        foreignObject.setAttribute('width', sample.getBoundingClientRect().width / realZoom);
        foreignObject.setAttribute('height', sample.getBoundingClientRect().height / realZoom);
        const el = document.createElement('div');
        el.classList.add('c-plan__svg-price');
        Plan.newClassStyle(item.cat.slug, el);
        const arrow = document.createElement('div');
        arrow.classList.add('c-plan__svg-price-arrow');
        if (Array.isArray(item.price)) {
            el.innerHTML = rangePricesString(item.price);
        } else {
            el.innerHTML = priceFormatString(item.price) + "€";
        }

        foreignObject.append(el);
        foreignObject.append(arrow);

        // Append place and price tag
        Plan.priceTag.append(place);
        Plan.priceTag.append(foreignObject);

        // Append new group to section svg
        section.append(Plan.priceTag);

        foreignObject.setAttribute('x', pathBBox.x - sample.getBoundingClientRect().width / 2 / realZoom + pathBBox.width / 2);
        foreignObject.setAttribute('y', pathBBox.y - sample.getBoundingClientRect().height / 2 - 6);

        Plan.priceTag.setAttribute('width', sample.getBoundingClientRect().width / realZoom);
        Plan.priceTag.setAttribute('height', sample.getBoundingClientRect().height / realZoom);

        // z-index issue
        svg.querySelector('#plan').append(section);

        Plan.handleZoomPriceTag();
    },
    handleZoomPriceTag: function () {
        const sample = Plan.svg.querySelector('.c-plan__svg-sampleprice');
        const svgBox = Plan.panZoom.getSizes(), realZoom = svgBox.realZoom;

        if (sample && Plan.priceTag !== null && realZoom) {
            const el = Plan.priceTag.querySelector('.c-plan__svg-price');
            const place = Plan.priceTag.querySelector('path');
            if (place) {
                const elCtn = el.parentElement;
                const pathBBox = place.getBBox();

                const widthSample = sample.getBoundingClientRect().width / realZoom;
                const heightSample = sample.getBoundingClientRect().height / realZoom;

                el.style.fontSize = 16 / realZoom + 'px';
                el.style.lineHeight = 16 / realZoom + 'px';
                el.style.padding = 14 / realZoom + 'px ' + 19.5 / realZoom + 'px';
                el.style.borderRadius = 6 / realZoom + 'px';
                el.style.width = widthSample + 'px';
                el.style.height = heightSample + 'px';

                const arrow = el.parentElement.querySelector('.c-plan__svg-price-arrow');
                const border = (Math.round(10 / realZoom) > 2) ? Math.round(10 / realZoom) : 1.5;
                arrow.style.borderWidth = border + 'px ' + border + 'px 0 ' + border + 'px';
                arrow.style.marginTop = '-' + 1 / realZoom + 'px';

                elCtn.setAttribute('width', widthSample);
                elCtn.setAttribute('height', heightSample);
                // elCtn.style.width = widthSample;
                // elCtn.style.height = heightSample;

                let y = pathBBox.y - heightSample - 4;
                if (window.matchMedia('(min-width:768px)').matches) {
                    y = pathBBox.y - heightSample - 2;
                }

                if (realZoom < 2.5) {
                    y = pathBBox.y - heightSample - 7;
                }

                elCtn.setAttribute('y', y);
                elCtn.setAttribute('x', pathBBox.x - widthSample / 2 + pathBBox.width / 2);
            }
        }
    },
    handlePlacesHover: function (event) {
        const el = event.target;
        // if available place
        if (el.classList.contains('plan-places')) {
            if (!el.classList.contains('unavailable')) {
                Plan.hidePlacePricesPopup();

                let {pricesList} = Plan.getPlacePriceInfos(el.id);

                let price = '';

                if (pricesList) {
                    if (pricesList.length > 1) {
                        price = [];
                        pricesList.forEach(priceEl => {
                            const catPrice = Plan.categoriesArray.find(cat => cat.id === priceEl.id);
                            /*
                            console.log(catPrice);
                            hasCodesPromo:false
                            id:5289483
                            name:"CAT 1 - OLYMPIA"
                            priceCategory:"CAT 1"
                            priceCategoryId:10495
                            valueCents:3850
                            valueEuros:"38,50"*/
                            const accessCodeJSON = getLocalStorage('access_code');
                            const accessCode = JSON.parse(accessCodeJSON)
                            if (catPrice.hasCodesPromo === true) {
                                if (accessCode && accessCode.code !== "" && accessCode.code !== null && accessCode.showId === Plan.showId) {
                                    if (catPrice.hasCodesPromo === true && accessCode.prices.some(price => price.priceId === catPrice.id)) {
                                        price.push(catPrice.valueCents);
                                    }
                                }
                            } else {
                                price.push(catPrice.valueCents);
                            }
                        });
                    } else {
                        price = pricesList[0].valueCents;
                    }

                    if (Array.isArray(price) && price.length === 1) {
                        price = price[0];
                    }
                    // console.log('price', price);

                    const svg = Plan.svg.querySelector('svg'), place = svg.getElementById(el.id), pathBBox = place.getBBox();

                    const item = {
                        price: price, cat: {slug: slugify(Plan.categoriesArray.find(cat => cat.id === pricesList[0].id).priceCategory)}
                    }
                    Plan.showPlacePricesPopup(place, item, svg, pathBBox);
                }

            }
        }
    },
    handlePlacesClick: function (event) {
        const el = event.target;

        // if limit error was shown, remove it
        removeErrorBanner('limit');

        if (window.matchMedia('(max-width:767px)').matches) {
            Plan.title.classList.add('hidden--');
            Plan.iconPanZoom.classList.add('hidden--');
        }

        // check if clicked element is a place and place is not in fosse
        if (el.classList.contains('plan-places')) {


            // if available place
            if (!el.classList.contains('unavailable')) {


                // if nb limit of places for one user is not reached = we can add another place
                if (Plan.selectedPlaces.length < placesNumberLimit) {

                    // if place is already selected = delete place from cart
                    if (el.classList.contains('selected')) {
                        Plan.toggleLoader('show');
                        deleteFromCart(Plan.showId, Plan.selectedPlaces.find(place => place.id === el.id), Plan.deletePlace, () => Plan.toggleLoader('hide', true));
                        // if place is available and not selected = add place to cart
                    } else {
                        Plan.beforeAddPlace(el.id);
                    }
                    // else nb limit is reached
                } else {
                    // if place was selected, remove it from cart
                    if (el.classList.contains('selected')) {
                        Plan.toggleLoader('show');
                        deleteFromCart(Plan.showId, Plan.selectedPlaces.find(place => place.id === el.id), Plan.deletePlace, () => Plan.toggleLoader('hide', true));
                        // if place wasn't selected, show error, limit is reached
                    } else {
                        Plan.toggleLoader('hide');
                        generateErrorBanner('limit', "Le nombre limite de places est atteint");
                    }
                }
            }
        }

        // check if clicked element is in fosse area
        if (el.id === "fosse-area" || el.id === 'fosse-area-2') {
            const fosseInputCtnInner = Plan.svg.querySelector('.fosse__input-ctnInner');
            if (fosseInputCtnInner) {
                const fosseInput = fosseInputCtnInner.querySelector('.fosse__input');
                if (fosseInput) {
                    // check if fosse is not full
                    const svg = Plan.svg.querySelector('svg');
                    if (!svg.classList.contains('full')) {

                        // on click update fosseInput container position and add visible class
                        Plan.handleZoomFosseInput(fosseInput, event);
                        fosseInputCtnInner.classList.add('visible');
                    }
                }
            }
        }

        // check if click element is price tag
        if (el.classList.contains('c-plan__svg-price-ctn')) {
            // click on price tag to toggle visiblity
            el.parentElement.classList.toggle('hidePrice');
        }
    },
    getPlacePriceInfos: function (id, fosse = false) {
        if (id) {
            let pricesList = null;
            let item = null;
            if (!fosse) {
                // find infos of priceId & seeticketsId in seatsArray array (got from getSeats API)
                const seat = Plan.seatsArray.find(seat => seat.id === id);

                if (seat) {
                    // get all prices
                    const accessCodeJSON = getLocalStorage('access_code');
                    const accessCode = JSON.parse(accessCodeJSON)
                    if (accessCode && accessCode.code !== "" && accessCode.code !== null && accessCode.showId === Plan.showId) {
                        pricesList = seat.priceListAvailable;
                    }
                    else {
                        pricesList = seat.priceListAvailable;
                    }
                    item = {
                        fosse: fosse, seeticketsId: seat.seatId
                    }
                }
            } else {
                pricesList = Plan.fossePrices;
                item = {
                    fosse: fosse
                }
            }

            return {item, pricesList};
        }
        return null;
    },
    beforeAddPlace: function (id, fosse = false, event = null) {
        let itemEl = null;
        const {item, pricesList} = Plan.getPlacePriceInfos(id, fosse);

        itemEl = item;
        const form = Plan.modalPrices.querySelector('.c-form');
        form.innerHTML = '';

        // if there is multiple prices
        if (pricesList && pricesList.length > 1) {
            /*
             console.log(pricesList);
            id:5289483
            valueCents:3850
            valueEuro:"38,50"*/
            let prices = "";
            let pricesLength = 0;

            function createRow(catPrice) {
                pricesLength++;
                const priceRow = `
                                <div class="form-row">
                                    <div class="form-group radio">
                                        <div class="radioCtn">
                                            <input type="radio" value="${catPrice.id}" id="${catPrice.id}" name="price"/>
                                            <span></span>
                                            <label for="${catPrice.id}" class="c-place__price">
                                                ${catPrice.name}
                                                <strong>${priceFormatString(catPrice.valueCents)}€</strong>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                `;
                prices += priceRow;
            }

            // forEach price, create markup
            pricesList.forEach(priceEl => {
                let catPrice;
                if (!fosse) {
                    catPrice = Plan.categoriesArray.find(cat => cat.id === priceEl.id);
                } else {
                    catPrice = priceEl;
                }
                /*
                 console.log(catPrice);
                hasCodesPromo:false
                 id:5289483
                 name:"CAT 1 - OLYMPIA"
                 priceCategory:"CAT 1"
                 priceCategoryId:10495
                 valueCents:3850
                 valueEuros:"38,50"*/
                const accessCodeJSON = getLocalStorage('access_code');
                const accessCode = JSON.parse(accessCodeJSON)
                if (accessCode && accessCode.code !== "" && accessCode.code !== null && accessCode.showId === Plan.showId) {
                    // createRow(catPrice);
                    if(catPrice.hasCodesPromo === false || (catPrice.hasCodesPromo === true && accessCode.prices.find(price => price.priceId === catPrice.id))) {//on affiche uniquement ceux qui on le code promo qui corresspond
                        createRow(catPrice);
                    }
                } else {
                    if (catPrice.hasCodesPromo === false) {
                        createRow(catPrice);
                    }
                }
            });

            if (pricesLength > 1) {
                // insert prices into modal content
                form.innerHTML = prices;
                // open prices modal
                Modal.open(Plan.modalPrices);
                // Close modal
                const closeButton = Plan.modalPrices.querySelector('.c-modal__close');
                if (closeButton) {
                    closeButton.addEventListener('click', () => {
                        Modal.close(Plan.modalPrices);
                    });
                }
                if (window.matchMedia('(min-width:768px)').matches) {
                    // Click outside modal
                    const backdrop = Plan.modalPrices.querySelector('.c-modal__backdrop');
                    if (backdrop) {
                        backdrop.addEventListener('click', () => {
                            Modal.close(Plan.modalPrices);
                        });
                    }
                }

                // listen change from prices modal
                form.addEventListener('change', handleChange);

                function handleChange() {
                    const radioSelected = form.querySelector('input[name="price"]:checked');
                    if (radioSelected) {
                        // close modal
                        Modal.close(Plan.modalPrices);
                        // if price is selected, add place to cart
                        itemEl = {...itemEl, priceId: radioSelected.value}

                        Plan.toggleLoader('show');
                        addToCart(Plan.showId, itemEl, null, () => Plan.toggleLoader('hide', true), event);
                        form.removeEventListener('change', handleChange);
                    }
                }
            } else {
                // if there is just one available price
                itemEl = {...itemEl, priceId: pricesList[0].id}

                Plan.toggleLoader('show');
                addToCart(Plan.showId, itemEl, null, () => Plan.toggleLoader('hide', true), event);
            }
        } else {
            // if there is just one available price
            itemEl = {...itemEl, priceId: pricesList[0].id}

            Plan.toggleLoader('show');
            addToCart(Plan.showId, itemEl, null, () => Plan.toggleLoader('hide', true), event);
        }
    },
    addPlace: function (item, onLoad = false, lastItemOnLoad = false) {
        //console.log('addPlace');
        Plan.toggleLoader('hide');
        const el = Plan.svg.querySelector('#' + item.id);

        if (!item.fosse) {
            if (el.classList.contains('unavailable')) {
                el.classList.remove('unavailable');
            }
            if (!el.classList.contains(item.cat.slug)) {
                Plan.newClassStyle(item.cat.slug, el);
            }

            // hide previous price popup
            Plan.hidePlacePricesPopup();
            // path
            el.classList.add('selected');
        }


        // update array
        Plan.selectedPlaces.push(item);

        // disabled or not svg
        if (Plan.selectedPlaces.length === placesNumberLimit) {
            Plan.svg.classList.add('disabled');
        }

        const listButtons = Plan.component.querySelector('.c-plan__selection-main-list-slider');

        const oldButtonActive = listButtons.querySelector('.active');
        if (oldButtonActive) {
            oldButtonActive.classList.remove('active');
        }

        const li = document.createElement('li');
        li.dataset.id = item.id;
        li.classList.add('active');


        const button = document.createElement('button');
        button.type = "button";

        const i = document.createElement('i');
        i.classList.add('icon-people');
        Plan.newClassStyle(item.cat.slug, i);
        button.append(i);

        li.append(button);

        listButtons.append(li);

        // scrollRight
        listButtons.scrollLeft = listButtons.scrollWidth;

        Plan.manageListButtonsSlider(listButtons);


        // card
        const planSelection = Plan.component.querySelector('.c-plan__selection');
        const places = Plan.component.querySelector('.c-plan__selection-main-places');
        const oldPlaceActive = places.querySelector('.active');
        if (oldPlaceActive) {
            oldPlaceActive.classList.remove('active');
        }

        const placesHTML = places.innerHTML;
        const htmlPlace = `<div class="c-place active" data-id="${item.id}">
                <div class="c-place__category"><span class="${Plan.newClassStyle(item.cat.slug)}">${item.locationLang} ${item.cat.name}</span></div>
                <div class="c-place__location">${!item.fosse ? `${planSelection.dataset.rang} ${item.row} ${planSelection.dataset.place} ${item.number}${item.strapontin ? ` ${planSelection.dataset.strapontin}` : ''}` : planSelection.dataset.placement}</div>
                <div class="c-place__price">${item.priceName} <strong>${priceFormatString(item.price)}€</strong></div>
                <button class="c-place__delete" type="button" title="${planSelection.dataset.delete}">
                    <svg id="icon-bin" xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 15.17 20.49">
                        <path d="M.76,4.08H14.45c.17,0,.34-.05,.48-.16,.28-.23,.32-.64,.09-.91-.03-.03-.06-.06-.09-.09-.14-.11-.31-.17-.49-.17h-2.69V.69h0v-.11s0-.03,0-.04c0-.01,0-.02,0-.02,0,0,0-.03-.01-.04,0-.01,0-.01,0-.02,0,0-.01-.03-.02-.04v-.02s-.02-.03-.03-.04h0s-.02-.04-.03-.06h0s-.06-.08-.1-.11c-.14-.11-.31-.17-.49-.17H4.17s-.09,0-.14,.02c-.13,.02-.25,.08-.35,.16-.15,.12-.24,.29-.26,.48V2.73H.75c-.18,0-.35,.07-.49,.17-.15,.12-.24,.29-.25,.48v.04c0,.19,.1,.37,.25,.48,.14,.11,.32,.17,.51,.16ZM4.92,1.35h5.33v1.39H4.92V1.35Z"/>
                        <path d="M4.67,8.38c.16,.12,.25,.31,.24,.51v8.18c0,.2-.08,.39-.24,.51-.14,.11-.3,.16-.48,.16h-.02c-.18,0-.36-.05-.5-.16-.15-.12-.24-.29-.25-.48V8.86c.01-.19,.1-.36,.25-.48,.14-.11,.31-.17,.49-.17h.02c.18,0,.35,.07,.49,.17Zm10.5-2.32v.09h0v13.65s0,0,0,.02v.03s0,.02,0,.03,0,.03,0,.04,0,.02,0,.03,0,.03-.01,.04c0,.01,0,.02,0,.03s-.01,.03-.01,.04c0,0,0,.02-.01,.03,0,.01-.01,.03-.02,.03,0,0-.01,.02-.02,.03,0,.01-.02,.02-.02,.03s-.01,.02-.02,.03c0,.01-.02,.02-.02,.03,0,0-.02,.02-.02,.03l-.03,.03s-.02,.02-.03,.03c-.14,.11-.3,.16-.48,.16H.74c-.15,0-.29-.04-.42-.12-.02-.01-.04-.03-.05-.04-.15-.12-.24-.29-.26-.48V6.13c.01-.19,.1-.36,.26-.48,.14-.11,.31-.17,.49-.17H14.45c.18,0,.35,.07,.49,.17,.04,.03,.07,.07,.1,.11,.01,.02,.03,.03,.04,.05h0s.02,.04,.03,.06h0s.01,.05,.02,.06v.02s.02,.04,.02,.05c0,0,0,0,0,.02,0,.02,0,.04,0,.05Zm-1.5,13.08V6.82H1.5v12.32H13.67ZM7.61,8.21h-.03c-.18,0-.35,.07-.49,.17-.15,.12-.24,.29-.25,.48v8.25c.01,.19,.1,.37,.25,.48h0c.14,.11,.32,.17,.5,.16h.02c.17,0,.34-.05,.48-.16,.16-.12,.25-.31,.24-.51V8.89c0-.2-.08-.39-.24-.51-.14-.11-.31-.17-.49-.17Zm3.42,0h-.03c-.18,0-.35,.07-.49,.17-.15,.12-.24,.29-.25,.48v8.25c.01,.19,.1,.37,.25,.48h0c.14,.11,.32,.17,.5,.16h.02c.17,0,.34-.05,.48-.16,.16-.12,.25-.31,.24-.51V8.89c0-.2-.08-.39-.24-.51-.14-.11-.31-.17-.49-.17Z"/>
                    </svg>
                </button>
                </div>
           `;

        places.innerHTML = placesHTML + htmlPlace;

        // on click insert place button
        const placesButtons = Plan.component.querySelectorAll('.c-plan__selection-main-list li button');
        if (placesButtons && placesButtons.length > 0) {
            placesButtons.forEach(button => {
                button.addEventListener('click', () => {
                    const oldActiveButton = Plan.component.querySelector('.c-plan__selection-main-list li.active');
                    if (oldActiveButton) {
                        oldActiveButton.classList.remove('active');
                        const oldActivePlace = Plan.component.querySelector('.c-plan__selection-main-places .c-place.active');
                        if (oldActivePlace) {
                            oldActivePlace.classList.remove('active');
                        }
                        Plan.hidePlacePricesPopup();
                    }

                    const li = button.closest('li');
                    li.classList.add('active');

                    Plan.component.querySelector('.c-plan__selection-main-places .c-place[data-id="' + li.dataset.id + '"]').classList.add('active');


                    // pan and zoom  + show price tag
                    Plan.zoomSelectedPlace(Plan.selectedPlaces.find(item => item.id === li.dataset.id));
                });
            });
        }


        // pan & zoom on plan
        if (!onLoad) {
            if (item.fosse) {
                Plan.component.querySelector('.c-plan__selection-main-list-slider li[data-id="' + item.id + '"].active button').click();
            } else {
                Plan.zoomSelectedPlace(item);
            }
        } else {
            if (lastItemOnLoad) {
                if (item.fosse) {
                    const timer = setInterval(() => {
                        if (Plan.loaded === true) {
                            Plan.component.querySelector('.c-plan__selection-main-list-slider li[data-id="' + item.id + '"].active button').click();
                            clearInterval(timer);
                        }
                    }, 100);
                } else {
                    Plan.zoomSelectedPlace(item);
                }
            }
        }
    },
    deletePlace: function (item) {
        Plan.toggleLoader('hide');

        // manage svg disabled or not
        if (Plan.svg.classList.contains('disabled') && Plan.selectedPlaces.length < (placesNumberLimit + 1)) {
            Plan.svg.classList.remove('disabled');
        }

        Plan.hidePlacePricesPopup();

        const listButtons = Plan.selectionInsert.querySelector('.c-plan__selection-main-list');
        const listPlaces = Plan.selectionInsert.querySelector('.c-plan__selection-main-places');

        // path on plan
        const targetEl = Plan.svg.querySelector('path#' + item.id);
        // path, remove selected
        if (!item.fosse && targetEl) {
            targetEl.classList.remove('selected');
        }

        // button
        const targetButton = listButtons.querySelector('li[data-id="' + item.id + '"]');
        // place
        const targetPlace = listPlaces.querySelector('.c-place[data-id="' + item.id + '"]');

        if (Plan.component.querySelector('.c-plan__selection-main-list li.active').dataset.id === item.id) {

            // get previous/next button
            const previousButton = targetButton.previousSibling;
            const nextButton = targetButton.nextSibling;

            let newDataId = "";

            // previous active
            if (previousButton) {
                previousButton.classList.add('active');
                newDataId = previousButton.dataset.id;

            } else if (nextButton) {
                nextButton.classList.add('active');
                newDataId = nextButton.dataset.id;
                listPlaces.querySelector('.c-place[data-id="' + newDataId + '"]').classList.add('active');
            }

            if (previousButton || nextButton) {
                listPlaces.querySelector('.c-place[data-id="' + newDataId + '"]').classList.add('active');
                // pan and zoom previous + show price tag
                Plan.zoomSelectedPlace(Plan.selectedPlaces.find(item => item.id === newDataId));
            } else {
                // hide panel
                Plan.component.querySelector('.c-plan__selection').classList.remove('active');
                // reset pan zoom
                Plan.hidePlacePricesPopup();
                Plan.panZoom.reset();
            }

        } else {
            const lastButton = Plan.component.querySelector('.c-plan__selection-main-list li:last-child');
            if (lastButton) {
                lastButton.classList.add('active');
                listPlaces.querySelector('.c-place[data-id="' + lastButton.dataset.id + '"]').classList.add('active');
            }
        }

        // remove button place
        targetButton.remove();
        // remove place item
        targetPlace.remove();


        // update array
        Plan.selectedPlaces = Plan.selectedPlaces.filter(place => place.id !== item.id);
        Plan.observeSelectedPlacesChange();

        const sliderButtons = Plan.component.querySelector('.c-plan__selection-main-list-slider');
        Plan.manageListButtonsSlider(sliderButtons);

    },
    manageListButtonsSlider: function (listButtons) {
        const allLis = listButtons.querySelectorAll('li');

        const nav = Plan.component.querySelector('.c-plan__selection-main-list__navigation');

        let limit = 10;
        if (window.matchMedia('(min-width:768px)').matches) {
            limit = 8;
        }
        if (allLis.length > limit) {
            nav.style.display = 'flex';
            listButtons.classList.add('nav--');
        } else {
            nav.style.display = 'none';
            if (listButtons.classList.contains('nav--')) {
                listButtons.classList.remove('nav--');
            }
        }
    },
    handleInsert: function () {
        // custom cursor
        if (window.matchMedia('(min-width:1025px)').matches) {
            // on mouseenter
            Plan.selectionInsert.addEventListener('mouseenter', () => {
                const cursorWrapper = document.querySelector('.cursor__wrapper:not(.header--)');
                Plan.selectionInsert.insertAdjacentElement('afterbegin', cursorWrapper);
            });
            // on mouseleave
            Plan.selectionInsert.addEventListener('mouseleave', () => {
                const cursorWrapper = document.querySelector('.cursor__wrapper:not(.header--)');
                document.querySelector('.app').insertAdjacentElement('beforeend', cursorWrapper);
            });
        }

        // toggle button to wrap/hide insert
        const toggle = Plan.selectionInsert.querySelector('.c-plan__selection-toggle');
        if (toggle) {
            toggle.addEventListener('click', () => {
                Plan.selectionInsert.classList.toggle('closed--');
            });
        }

        const listButtons = Plan.component.querySelector('.c-plan__selection-main-list-slider');
        if (listButtons) {

            const nav = Plan.component.querySelector('.c-plan__selection-main-list__navigation');

            const prevButton = nav.querySelector('.c-plan__selection-main-list__navigation-prev');
            const nextButton = nav.querySelector('.c-plan__selection-main-list__navigation-next');

            nextButton.addEventListener('click', () => {
                if (listButtons.scrollLeft < listButtons.offsetWidth) {
                    listButtons.scrollLeft += 26;
                    prevButton.disabled = false;
                } else {
                    nextButton.disabled = true;
                }
            });

            prevButton.addEventListener('click', () => {
                if (listButtons.scrollLeft > 26) {
                    listButtons.scrollLeft -= 26;
                    nextButton.disabled = false;
                } else {
                    listButtons.scrollLeft = 0;
                    prevButton.disabled = true;
                }
            });
        }

        // on click insert delete button
        Plan.component.addEventListener('click', (event) => {
            const target = event.target || event.srcElement || event.currentTarget;
            if (target.classList.contains('c-place__delete') || target.closest('button')?.classList.contains('c-place__delete')) {
                Plan.toggleLoader('show');
                deleteFromCart(Plan.showId, Plan.selectedPlaces.find(item => item.id === event.target.closest('.c-place').dataset.id), Plan.deletePlace, () => Plan.toggleLoader('hide', true));
            }
        });
    },
    setAccessCode: function (promoCode, prices) {
        const showId = Plan.showId;
        if (getLocalStorage('access_code')) {
            removeLocalStorage('access_code');
        }
        saveLocalStorage('access_code', JSON.stringify({'code': promoCode, 'showId': showId, 'prices': prices}));
        Plan.updateWithAccessCode(promoCode);
    },
    updateWithAccessCode: function (promoCode) {
        // console.log('updateWithAccessCode', promoCode);
        if (promoCode) {
            // update toolbar promocode button
            Plan.component.querySelector('.c-plan__svg-tools-promocode i').classList.remove('icon-arrow-down');
            Plan.component.querySelector('.c-plan__svg-tools-promocode i').classList.add('icon-validation');
        } else {
            // update toolbar promocode button
            Plan.component.querySelector('.c-plan__svg-tools-promocode i').classList.remove('icon-validation');
            Plan.component.querySelector('.c-plan__svg-tools-promocode i').classList.add('icon-arrow-down');
        }
        // update categories modal
        Plan.updateCategoriesModal();
        // update price tag
        // update fosse input
        Plan.updateRangeFosseInput(true);


        // api access code return
        /* datas:
         accessCodes:Array(2)
         0:{code: 'OLYCE2022!', nbLeft: 0, priceId: 5265677, isUsable: true, isUnlimited: true}
         1:{code: 'OLYCE2022!', nbLeft: 0, priceId: 5265679, isUsable: true, isUnlimited: true}
         length:2*/
    },
    handleResizeFosseInput: function () {
        const sample = Plan.component.querySelector('.fosse__input.sample--');
        const fosseInputInSvgCtn = Plan.svg.querySelector('.fosse__input-ctn');
        const svgBox = Plan.panZoom.getSizes(), realZoom = svgBox.realZoom;

        const elCtn = fosseInputInSvgCtn;

        const el = elCtn.querySelector('.fosse__input');
        const elInner = elCtn.querySelector('.fosse__inputInner');

        el.style.width = sample.getBoundingClientRect().width / realZoom + 'px';
        el.style.height = sample.getBoundingClientRect().height / realZoom + 'px';

        elInner.style.padding = 9 / realZoom + 'px';
        elInner.style.borderRadius = 6 / realZoom + 'px';

        const quantityInputs = el.querySelectorAll('input');
        if (quantityInputs && quantityInputs.length > 0) {
            quantityInputs.forEach(input => {
                input.style.width = 35 / realZoom + 'px';
                input.style.height = 40 / realZoom + 'px';
                input.style.fontSize = 12 / realZoom + 'px';
            });
        }

        const quantityButtons = el.querySelectorAll('.quantity-button');
        if (quantityButtons && quantityButtons.length > 0) {
            quantityButtons.forEach(button => {
                button.style.width = 40 / realZoom + 'px';
                button.style.height = 40 / realZoom + 'px';
                button.style.fontSize = 18 / realZoom + 'px';
                //   button.style.lineHeight = 38 / realZoom + 'px';
                button.style.borderWidth = 1 / realZoom + 'px';
            });
        }

        const prices = el.querySelector('.fosse__input-prices');
        if (prices) {
            prices.style.marginTop = 10 / realZoom + 'px';
            prices.style.fontSize = 16 / realZoom + 'px';
        }

        const arrow = el.querySelector('.fosse__input-arrow');
        const border = (Math.round(10 / realZoom) > 2) ? Math.round(10 / realZoom) : 1.5;
        arrow.style.borderWidth = border + 'px ' + border + 'px 0 ' + border + 'px';
        const margin = (Math.round(1 / realZoom) > .5) ? Math.round(1 / realZoom) : .5;
        arrow.style.marginTop = '-' + margin / realZoom + 'px';


        elCtn.setAttribute('width', sample.getBoundingClientRect().width / realZoom);
        elCtn.setAttribute('height', (sample.getBoundingClientRect().height + 10) / realZoom);

        Plan.svg.querySelector('.fosse__input-ctnInner').setAttribute('width', sample.getBoundingClientRect().width / realZoom);
        Plan.svg.querySelector('.fosse__input-ctnInner').setAttribute('height', (sample.getBoundingClientRect().height + 10) / realZoom);
    },
    handleZoomFosseInput: function (fosseInput, event) {
        const sample = Plan.component.querySelector('.fosse__input.sample--');
        const fosseInputInSvgCtn = Plan.svg.querySelector('.fosse__input-ctn');

        const currentPan = Plan.panZoom.getPan();

        const svgBox = Plan.panZoom.getSizes(), realZoom = svgBox.realZoom;

        if (sample && fosseInputInSvgCtn && realZoom) {
            Plan.handleResizeFosseInput();

            const elCtn = fosseInputInSvgCtn;

            let x;
            let y;
            //if (window.matchMedia('(min-width:768px)').matches) {
            if (event) {
                const pointerX = event.offsetX;
                const pointerY = event.offsetY;

                const xOffset = (pointerX - currentPan.x) / realZoom - sample.getBoundingClientRect().width / 2 / realZoom;
                const yOffset = (pointerY - currentPan.y) / realZoom - (sample.getBoundingClientRect().height + 10) / realZoom;

                x = xOffset;
                y = yOffset;

                Plan.fosseInputPosition = {
                    x: (pointerX - currentPan.x) / realZoom, y: (pointerY - currentPan.y) / realZoom,
                }
            } else {
                if (Plan.fosseInputPosition.x && Plan.fosseInputPosition.y) {
                    x = Plan.fosseInputPosition.x - sample.getBoundingClientRect().width / 2 / realZoom;
                    y = Plan.fosseInputPosition.y - (sample.getBoundingClientRect().height + 10) / realZoom;
                } else {
                    x = svgBox.viewBox.width / 2 - sample.getBoundingClientRect().width / 2 / realZoom;
                    y = svgBox.viewBox.height / 2 + 60 - (sample.getBoundingClientRect().height + 10) / realZoom;
                }
            }
            // } else {
            //     x = svgBox.viewBox.width/2 - sample.getBoundingClientRect().width / 2/realZoom;
            //     y = svgBox.viewBox.height/2 + 100 - (sample.getBoundingClientRect().height + 10)/realZoom;
            // }

            if (x && y) {
                elCtn.setAttribute('x', x);
                elCtn.setAttribute('y', y);
            }

        }
    }
};

export default Plan;